
import { Component, Vue } from 'vue-property-decorator';
import { store } from '@/store';
import { dispatchCheckLoggedIn, dispatchLogInByToken } from '@/store/main/actions'
import { readIsLoggedIn } from '@/store/main/getters';

const startRouteGuard = async (to, from, next) => {
  await dispatchCheckLoggedIn(store);

  if (to.query.token) {
    if (to.query.token !== '' && !readIsLoggedIn(store)) {
      // console.log('%c logging in', 'color:gold;')
      // TODO Логин по токену
      await dispatchLogInByToken(store, {
        authToken: to.query.token,
        language: navigator.language.slice(0, 2),
      })

      if (readIsLoggedIn(store)) {
        window.location.href = '/dashboard'
      } else {
        const token = `?token=${to.query.token}`;
        window.location.href = to.fullPath.replace(token, '');
      }
    } else  {
      const token = `?token=${to.query.token}`;
      window.location.href = to.fullPath.replace(token, '');
    }
    next()
  } else {
    // console.debug('%c readIsLoggedIn Start.vue', 'color:magenta;')
    if (readIsLoggedIn(store)) {
      if (to.path === '/' || to.path === '/login' || to.path === '/register') {
        // console.debug('%c ??????????????', 'color:red;font-size:18px;')
        // next('/dashboard');
        window.location.pathname = '/dashboard'
      } else {
        next();
      }
    } else if (readIsLoggedIn(store) === false) {
      if ((to.path as string).startsWith('/dashboard') || (to.path as string).startsWith('/finance')) {
        // next('/');
        window.location.pathname = '/'
      } else {
        next();
      }
    }
  }
};

@Component
export default class Start extends Vue {
  public beforeRouteEnter(to, from, next) {
    startRouteGuard(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    startRouteGuard(to, from, next);
  }

  public initJivoChat() {
    console.debug('%c initJivoChat()', 'color:aqua;font-size:24px;')

    const jivoExtUrl = '//code.jivosite.com/widget/2W9ItVmZB4'
    // const jivoLocalUrl = ''

    const script = document.createElement('script')
    script.src = jivoExtUrl
    script.async = false
    document.head.appendChild(script)

    // ******************************
    /*
     Callback function that is called immediately after JivoChat is loaded
     */
    function jivo_onLoadCallback(){
      console.debug('%c jivo_onLoadCallback()', 'color:lime;font-size:18px;')

      console.debug('window:', window)

      // Create a DIV element for the label
      // @ts-ignore
      window.jivo_cstm_widget = document.createElement('div');

      // @ts-ignore
      console.debug('window.jivo_cstm_widget', window.jivo_cstm_widget)

      // @ts-ignore
      const jivo_cstm_widget = window.jivo_cstm_widget

      jivo_cstm_widget.setAttribute('id', 'jivo_custom_widget');
      document.body.appendChild(jivo_cstm_widget);

      // Adds handlers click on the icon - to maximize the window when clicked
      jivo_cstm_widget.onclick = function(){
        // @ts-ignore
        jivo_api.open();
      }

      // Change the CSS class if there are agents online
      // @ts-ignore
      if (jivo_config.chat_mode == "online"){
        jivo_cstm_widget.setAttribute("class", "jivo_online");
      }

      // Now you can show the user a shortcut
      // @ts-ignore
      window.jivo_cstm_widget.style.display='block';
    }

    /*
     Callback function jivo_onOpen and jivo_onClose called whenever the chat window JivoChat is expanded or collapsed by the user or by the proactive invitations rule.
     */
    function jivo_onOpen(){
      console.debug('%c jivo_onOpen()', 'color:lime;font-size:18px;')
      // If chat is deployed - hide shortcut
      // @ts-ignore
      if (jivo_cstm_widget) jivo_cstm_widget.style.display = 'none';
    }
    function jivo_onClose(){
      console.debug('%c jivo_onClose()', 'color:lime;font-size:18px;')
      // If chat is minimized - show label
      // @ts-ignore
      if (jivo_cstm_widget) jivo_cstm_widget.style.display = 'block';
    }
    // ******************************
  }

  public beforeMount() {
    // console.debug('%c beforeMount locales', 'color:gold;')
    const locale = String(localStorage.getItem('locale'))
    if (this.$i18n.locale !== locale) {
      if (['en', 'ru'].includes(locale)) {
        this.$i18n.locale = locale
      }
    }
  }

  public mounted() {
    // console.debug('%c Start.vue mounted', 'color:orangered;font-size:18px;')
    // this.initJivoChat()
  }
}
